import { defineStore } from 'pinia';
import { formatDistanceToNow } from 'date-fns';
import getPath from '@/utils/getPath';
import { apiGet, apiPost } from '@/utils/api';
import * as helpers from '@/utils/helpers';
import { getAuthenticatedHeaders } from '@/utils/auth';
import { toast } from 'vue3-toastify';

export const useAssignmentsStore = defineStore('assignments', {
  state: () => ({
    readyAssignments: [],
    wipAssignments: [],
    wipCursor: 0,
    wipRecords: 0,
    wipLimit: 25,
    wipPage: 1,
    wipDirection: 'asc',
    wipField: 'time_in_column',
    recentAssignments: [],
    recentCursor: 0,
    recentRecords: 0,
    recentLimit: 25,
    recentPage: 1,
    recentDirection: 'asc',
    recentField: 'last_activity',
    isLeadLoading: true,
    isWipLoading: true,
    isRecentLoading: true,
    emptyLead: false,
  }),
  getters: {
    sortWipAssignments: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.wipAssignments);
      };
    },
    sortRecentAssignments: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.recentAssignments);
      };
    },
    getWipAssignmentById: state => {
      return id => {
        return state.wipAssignments.find(
          assignment => id === assignment.work_item_id
        );
      };
    },
    getReadyAssignmentById: state => {
      return id => {
        return state.readyAssignments.find(
          assignment => id === assignment.work_item_id
        );
      };
    },
    workItemIsComplete: state => {
      return id => {
        return state.recentAssignments.find(
          assignment => parseInt(id) === parseInt(assignment.work_item_id)
        );
      };
    },
    workItemIsPriority: state => {
      return id => {
        return [
          ...state.readyAssignments,
          ...state.recentAssignments,
          ...state.wipAssignments,
        ].find(
          assignment =>
            parseInt(id) === parseInt(assignment.work_item_id) &&
            assignment.is_priority === true
        );
      };
    },
  },
  actions: {
    async fetchUserLead() {
      const headers = await getAuthenticatedHeaders();
      this.isLeadLoading = true;
      try {
        const responseData = await apiGet(
          getPath('assignments?type=lead'),
          headers
        );

        this.readyAssignments = responseData?.data.map(assignment => ({
          ...assignment,
          time_in_column: assignment.last_auxo_activity_dtm
            ? formatDistanceToNow(new Date(assignment.last_auxo_activity_dtm))
            : null,
        }));

        this.emptyLead = false;
        this.isLeadLoading = false;
      } catch (err) {
        console.error(err);
        this.isLeadLoading = false;
      }
    },
    async fetchUserWip(
      auxo_user_id = null,
      page = 1,
      direction = 'asc',
      field = 'time_in_column'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.isWipLoading = true;
      this.wipPage = page;
      this.wipDirection = direction;
      this.wipField = field;

      const limit = localStorage.getItem('wip_pagination')
        ? parseInt(localStorage.getItem('wip_pagination'), 10)
        : this.wipLimit;

      try {
        const payload = {
          cursor: page ? (page - 1) * limit : this.wipCursor,
          limit,
          field,
          direction,
        };

        let user_id = null;
        auxo_user_id ? (user_id = auxo_user_id) : (user_id = '');

        const responseData = await apiGet(
          getPath(`assignments?type=wip&user_id=${user_id}`),
          headers
        );

        this.wipAssignments = responseData?.data.map(assignment => ({
          ...assignment,
          time_in_column: assignment.mod_timest
            ? formatDistanceToNow(new Date(assignment.mod_timest), {
                includeSeconds: false,
              })
            : null,
          last_activity: assignment.mod_timest,
        }));

        this.wipAssignments.sort((a, b) => {
          if (direction === 'asc') {
            if (field === 'time_in_column' && a.mod_timest && b.mod_timest) {
              return new Date(a.mod_timest) - new Date(b.mod_timest);
            }
            return a[field].localeCompare(b[field], undefined, {
              sensitivity: 'base',
            });
          } else {
            if (field === 'time_in_column' && a.mod_timest && b.mod_timest) {
              return new Date(b.mod_timest) - new Date(a.mod_timest);
            }
            return b[field].localeCompare(a[field], undefined, {
              sensitivity: 'base',
            });
          }
        });

        // TODO: Reimplement Pagination
        // this.wipCursor = response?.data?.cursor || 1;
        // this.wipRecords = response?.data?.records || 0;

        this.isWipLoading = false;
      } catch (err) {
        console.error(err);
        this.isWipLoading = false;
      }
    },
    async fetchRecentUserAssignments(
      auxo_user_id = null,
      page = 1,
      direction = 'desc',
      field = 'mod_timest'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.isRecentLoading = true;
      this.recentPage = page;
      this.recentDirection = direction;
      this.recentField = field;
      try {
        const limit = localStorage.getItem('recent_lead_pagination')
          ? parseInt(localStorage.getItem('recent_lead_pagination'), 10)
          : this.recentLimit;

        const auxoUserId = auxo_user_id ? auxo_user_id : '';

        const responseData = await apiGet(
          getPath(`assignments/history?userId=${auxoUserId}`),
          headers
        );

        this.recentAssignments = responseData?.data.map(assignment => ({
          ...assignment,
          time_in_column: assignment.last_auxo_activity_dtm
            ? formatDistanceToNow(new Date(assignment.last_auxo_activity_dtm))
            : null,
        }));

        this.recentAssignments.sort((a, b) => {
          if (direction === 'asc') {
            return a[field].localeCompare(b[field], undefined, {
              sensitivity: 'base',
            });
          } else {
            return b[field].localeCompare(a[field], undefined, {
              sensitivity: 'base',
            });
          }
        });

        // TODO: Reimplement Pagination
        // this.recentCursor = responseData?.data?.cursor || 1;
        // this.recentRecords = responseData?.data?.records || 0;

        this.isRecentLoading = false;
      } catch (err) {
        console.error(err);
        this.isRecentLoading = false;
      }
    },
    async refreshTheLead() {
      const headers = await getAuthenticatedHeaders();
      this.isLeadLoading = true;
      try {
        const responseData = await apiPost(getPath('assignments'), headers);

        if (!responseData.success) {
          const message =
            responseData.status_code == 409
              ? 'There was an error refreshing the lead! Please try again.'
              : responseData.message;
          toast.error(message);
        } else {
          this.readyAssignments = responseData?.data.map(assignment => ({
            ...assignment,
            time_in_column: assignment.last_auxo_activity_dtm
              ? formatDistanceToNow(new Date(assignment.last_auxo_activity_dtm))
              : null,
          }));
        }
        if (responseData.data.length == 0) {
          this.emptyLead = true;
        } else {
          this.emptyLead = false;
        }

        return responseData;
      } catch (err) {
        console.error(err);
        return err;
      } finally {
        this.isLeadLoading = false;
      }
    },
  },
});
