import { defineStore } from 'pinia';
import getPath from '../utils/getPath';
import { getAuthenticatedHeaders } from '@/utils/auth';
import { getUserInitials } from '@/utils/helpers';
import router from '@/router';
import * as helpers from '@/utils/helpers';
import { apiGet, apiPost } from '@/utils/api';

const SYSTEM_USER = 'sys';

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    notFound: false,
    statusLookup: [],
    activeUser: null,
    status: null,
    usersLoading: false,
  }),
  getters: {
    /**
     * Returns the full name of a user with the given username.
     */
    getUserFullName: state => {
      return username => {
        if (!username) return null;
        const user = state.users.find(
          user => user?.username?.toLowerCase() === username.toLowerCase()
        );

        if (username === SYSTEM_USER) return 'System User';
        return user?.full_name;
      };
    },
    getGroupOfUsersNames: state => usersArray => {
      if (!state.users.length) return;

      return usersArray.map(e => {
        return {
          name: `${e.first_name} ${e.last_name}`,
          is_active: e.is_active,
        };
      });
    },
    getUsernames: state => {
      return state.users.map(user => {
        return {
          key: user.username,
          value: `${user.first_name} ${user.last_name}`,
        };
      });
    },
    getUserById: state => {
      return userId => {
        const user = state.users.find(
          user => user.user_id || user.auxo_user_id === userId
        );
        return {
          ...user,
          initials: getUserInitials(user),
        };
      };
    },
    getActiveUsersRoles: state => {
      return state.activeUser['cognito:groups'];
    },
    activeUsers: state => {
      return state.users.filter(user => user.is_active);
    },
    getUserByUsername: state => {
      return username => {
        if (!username) return null;
        const user = state.users.find(
          user => user.username.toLowerCase() === username.toLowerCase()
        );
        return {
          ...user,
          initials: getUserInitials(user),
        };
      };
    },
    getUserInitials: state => {
      return username => {
        if (!username) return null;

        const user = state.users.find(
          user => user.username.toLowerCase() === username.toLowerCase()
        );
        return user ? user.first_name[0] + user.last_name[0] : null;
      };
    },
    getUserPicture: state => {
      return username => {
        if (!username) return null;

        return (
          state.users.find(
            user => user.username.toLowerCase() === username.toLowerCase()
          )?.photo_url || null
        );
      };
    },
    sortUsers: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.users);
      };
    },
    hasPermission: state => {
      return async (workflow, _, validSkus) => {
        return await helpers.hasPermission(
          workflow,
          state.activeUser?.accessToken,
          validSkus
        );
      };
    },
  },
  actions: {
    setStatus(status) {
      this.status = status;
      localStorage.setItem('userStatus', this.status?.name);
      return status;
    },
    async getAllUsers() {
      this.usersLoading = true;
      const headers = await getAuthenticatedHeaders();
      try {
        const response = await apiGet(getPath('users'), headers);
        console.log(response);

        this.users = response.data
          .map(user => {
            user.photo_url = user.photo_url || 'none';
            const full_name = [user?.first_name, user?.last_name]
              .filter(e => e)
              .join(' ');

            return {
              ...user,
              username: helpers.getUsername(user.email),
              full_name,
              informal_title: helpers.formatUserRoles(user?.user_roles),
              label: full_name,
            };
          })
          .sort((a, b) => {
            return a.first_name.localeCompare(b.first_name, 'en', {
              sensitivity: 'accent',
            });
          });
      } catch (err) {
        console.error(err);
      } finally {
        this.usersLoading = false;
      }
    },
    async getUserByEmail(email) {
      if (!email) return;
      const headers = await getAuthenticatedHeaders();
      // TODO: when cognito returns auxo_user_id remove this and call /users/<auxo_user_id>

      this.notFound = false;
      try {
        const response = await apiGet(
          getPath(`users?email=${email}&expect_single=true`),
          headers
        );

        if (response.status_code === 200) {
          const user = response.data;

          return {
            ...user,
            username: helpers.getUsername(user.email),
          };
        } else {
          throw Error(`Could not get user.`);
        }
      } catch (err) {
        console.error(err);
        this.notFound = true;
      }
    },

    async getUser(auxo_user_id) {
      const headers = await getAuthenticatedHeaders();
      this.notFound = false;
      try {
        const response = await apiGet(
          getPath(`users/${auxo_user_id}`),
          headers
        );

        if (response.status_code === 200) {
          const user = response.data;

          return {
            ...user,
            username: helpers.getUsername(user.email),
          };
        } else {
          throw Error(`Could not get user.`);
        }
      } catch (err) {
        console.error(err);
        this.notFound = true;
      }
    },
    async logOut() {
      sessionStorage.removeItem('activityDrafts');
      router.push('/logout');
    },
    async authActivity(action) {
      const headers = await getAuthenticatedHeaders();

      try {
        const response = await apiPost(
          getPath(`users/auth_activity?action=${action}`),
          headers
        );

        if (response.status_code === 200) {
          const data = response.data;

          return data;
        } else {
          throw Error(`Could not track login/logout activity.`);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
});
