<template>
  <!-- <div class="bg-white p-2 flex justify-end">
    <div class="w-6 mr-3 cursor-pointer">
      <img :src="SortArrowsIcon" class="w-full" />
    </div>
    <div class="w-8 cursor-pointer">
      <img :src="MagnifyGlassIcon" class="w-full" />
    </div>
  </div> -->
  <table class="w-full text-left border-0 relative cursor-default table-fixed">
    <thead class="sticky top-[-2em] bg-white">
      <tr>
        <!-- <th class="w-7 place-content-center">
          <input class="cursor-pointer mt-1.5" type="checkbox" />
        </th> -->
        <template v-for="(header, index) in USERS_HEADERS" :key="index">
          <TableHeader
            :id="header.key"
            :title="header.value"
            :show-sort="header.sort"
            :style="header.key === 'assignees' ? 'justify-center' : null"
            :title-styling="'text-rev-xl text-gray-700'"
            @sort="sortUsers"
          />
        </template>
      </tr>
    </thead>
    <td
      v-if="users_store.usersLoading === true"
      :colspan="USERS_HEADERS.length"
    >
      <div class="flex items-center justify-center">
        <LoadingSpinner />
      </div>
    </td>
    <tbody v-else class="text-rev-sm font-normal text-gray-700">
      <template v-for="(item, index) in users_store.activeUsers" :key="index">
        <tr
          :data-index="index"
          class="z-10 cursor-pointer text-gray-700 font-normal text-rev-sm"
          :class="{
            'bg-gray-100': index % 2 === 1,
            'bg-white': index % 2 === 0,
            'outline outline-1 outline-primary-300 bg-primary-100':
              props.selectedUser?.username === item.username,
          }"
          @click="emit('setSelectedUser', item)"
        >
          <!-- <td class="w-7 place-content-center">
            <input class="cursor-pointer mt-1" type="checkbox" />
          </td> -->
          <td
            v-for="(header, tableDataIndex) in USERS_HEADERS"
            :key="tableDataIndex"
            class="ml-1"
          >
            <TableCellView :item="item" :header="header" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useUsersStore } from '@/stores/useUsers';
import { onMounted } from 'vue';
import TableCellView from '../common/TableCellView.vue';

const emit = defineEmits(['showDetails', 'setSelectedUser']);

const users_store = useUsersStore();

const props = defineProps({
  selectedUser: {
    type: Object,
    default: null,
  },
  setSelectedUser: {
    type: Function,
    default: () => 1,
  },
});

const USERS_HEADERS = [
  { key: 'full_name', value: 'Name', sort: true },
  { key: 'email', value: 'Email', sort: true },
  { key: 'informal_title', value: 'User Roles', sort: true },
];

onMounted(async () => {
  if (users_store.usersLoading == false) await users_store.getAllUsers();
});

function sortUsers(order = 'asc', id) {
  users_store.users.value = users_store.sortUsers(order, id);
}
</script>
