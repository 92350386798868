// From CPRD-820, create pattern for websockets in the frontend
// For ready state and other websocket specific built in APIs checkout
// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState

import { toast } from 'vue3-toastify';
import { defineStore } from 'pinia';
import { useGamePlanStore } from './useGamePlans';
import { useUsersStore } from './useUsers';
import { useWorkItemsStore } from './useWorkItems';
import { PERMISSIONED_WORKFLOWS } from '@/utils/constants';
import router from '@/router';

export const WEBSOCKET_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const useWebSockets = defineStore('useWebSockets', {
  state: () => ({
    connection: undefined,
    token: undefined,
    baseURL: process.env.VUE_APP_WEB_SOCKET_API_GATEWAY_ENDPOINT,
    backoff: 2000, // use for exponential backoff when trying to reconnect on error
    loading: false,
    ping: undefined,
    INTERVAL: 5 * 60 * 1000,
    MAX_BACKOFF: 1 * 60 * 1000,
  }),
  getters: {
    getEditGamePlan() {
      return this.editGamePlan;
    },
    /* Returns true if loading, connecting, or connected */
    checkConnection() {
      // 0/1 means connecting/connected
      if (
        this.connection?.readyState == 0 ||
        this.connection?.readyState == 1 ||
        this.loading
      ) {
        return true;
      }
      return false;
    },
  },
  actions: {
    async websocketEventRouter(message) {
      const key = message?.event_type;
      if (
        key == 'work_strategy_unlocked' ||
        key == 'work_strategy_updated' ||
        key == 'adm_draft_ws_canceled'
      ) {
        const users_store = useUsersStore();
        const gameplan_store = useGamePlanStore();
        if (
          users_store.getActiveUsersRoles.some(userRole =>
            PERMISSIONED_WORKFLOWS.VIEW_GAME_PLAN_TAB.includes(userRole)
          )
        ) {
          gameplan_store.isStrategyLocked = false;
          gameplan_store.editingMessage = '';
          gameplan_store.resetWorklists();
        }
      } else if (key == 'work_strategy_locked') {
        const gameplan_store = useGamePlanStore();
        gameplan_store.isStrategyLocked = true;
        gameplan_store.editingMessage = `${message.payload.owner} is currently editing the Game Plan.`;
      } else if (key == 'draft_work_strategy_saved') {
        const gameplan_store = useGamePlanStore();
        gameplan_store.isStrategyLocked = true;
        gameplan_store.editingMessage = `The Game Plan strategy is being recalculated. Edit Mode is unavailable.`;
      } else if (key == 'claim_status_published') {
        if (
          router.currentRoute.value.fullPath ===
          `/lead/${message?.payload.work_item_id}`
        ) {
          const workItems_store = useWorkItemsStore();
          workItems_store.isClaimStatusLoading = false;
          workItems_store.fetchWorkItemActivities(
            message?.payload.work_item_id
          );
        }
      } else if (key == 'claim_status_not_available') {
        if (
          router.currentRoute.value.fullPath ===
          `/lead/${message?.payload.work_item_id}`
        ) {
          const workItems_store = useWorkItemsStore();
          workItems_store.isClaimStatusLoading = false;
          toast.error(`Claim Status check not available, try again later`);
        }
      } else if (key == 'auxo_user_deactivated') {
        const users_store = useUsersStore();
        users_store.logOut();
      } else if (key === 'auxo_user_photo_updated') {
        const users_store = useUsersStore();
        const user_detail = await users_store.getUserByEmail(
          users_store.activeUser.email
        );
        users_store.getAllUsers();
        user_detail.photo_url
          ? (users_store.activeUser.photo_url = user_detail.photo_url)
          : (users_store.activeUser.photo_url = 'none');
        users_store.activeUser.initials = [
          user_detail.first_name[0].toUpperCase(),
          user_detail.last_name[0].toUpperCase(),
        ].join('');
      }
    },
    async keepalive() {
      const message = {
        action: 'ping',
      };
      this.ping = setInterval(() => {
        try {
          this.connection.send(JSON.stringify(message));
        } catch {
          console.debug('error');
        }
      }, this.INTERVAL);
    },

    async reconnect() {
      this.backoff = Number(this.backoff) * 2;
      clearInterval(this.ping);
      console.debug('Attempting to reconnect.');
      console.debug('Backoff set to: ' + this.backoff);
      console.debug('The connection url is: ' + this.baseURL);
      await this.connect();
    },
    async connect() {
      if (this.checkConnection) return;
      this.loading = true;

      setTimeout(async () => {
        const idToken = sessionStorage.getItem('id_token');
        if (!idToken) return;

        const url = this.baseURL + '?idToken=' + idToken;
        const ws = new WebSocket(url);

        ws.onmessage = ev => {
          const message = JSON.parse(ev?.data);
          console.debug(message);
          this.websocketEventRouter(message);
        };

        ws.onerror = err => {
          console.error(`${JSON.stringify(err)}`);
          // throw err;
        };

        ws.onopen = ev => {
          console.debug('Socket is now open.', ev);

          this.loading = false;
          this.connection = ws;
          this.keepalive();
          this.backoff = 2000;
        };

        ws.onclose = ev => {
          console.debug('WebSocket connection closed:', ev);
          console.debug('Close code:', ev.code);
          console.debug('Close reason:', ev.reason);

          switch (ev.code) {
            case 1005: // Closed no status (logging out)
              break;
            default:
              this.loading = false;
              this.reconnect();
          }
        };
      }, Number(Math.min(this.backoff, this.MAX_BACKOFF)));
    },
    async close() {
      console.debug('Closing websocket connection.');
      this.loading = false;
      this.connection?.close();
      this.connection = undefined;
    },
  },
});
