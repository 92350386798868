<template>
  <div class="relative flex flex-col justify-end" :class="props.wrapperClass">
    <div class="flex">
      <label
        v-if="label"
        :for="id"
        class="flex items-center mb-2 text-sm font-medium text-primary"
        :class="{
          [props.class]: true,
          'text-red-700': props.error,
        }"
      >
        {{ label }}
      </label>
      <slot />
    </div>
    <div
      class="flex items-center rounded"
      :class="{
        'bg-white': !props.disabled,
        'bg-gray-50': props.disabled,
      }"
    >
      <input
        :id="id"
        ref="input"
        :autofocus="props.autofocus"
        :type="props.type"
        :disabled="props.disabled"
        :value="modelValue"
        class="bg-gray-50 border border-gray-300 text-primary text-sm rounded-lg block w-full p-2.5 no-default-ring"
        :tabindex="props.tabindex"
        :step="props.step"
        :pattern="props.pattern"
        :class="{
          [props.class]: true,
          '!p-[5px]': props.type == 'date',
          'bg-red-50 border border-red-500 text-red-900 ': props.error,
          'bg-green-50 border border-green-500 text-green-900 ': props.isValid,
        }"
        :min="assignMinValue()"
        :max="assignMaxValue()"
        :required="props.required"
        :placeholder="props.placeholder"
        @click="
          e => {
            e.stopPropagation();
          }
        "
        @blur="e => (props.blur ? props.blur(e) : () => {})"
        @input="
          event => {
            let { value } = event.target;

            if (value < props.minNum && props.minNum != undefined) {
              input.value = '';
              emit('update:modelValue', value);
              return;
            } else if (value > props.maxNum && props.maxNum != undefined) {
              input.value = '';
              emit('update:modelValue', value);
              return;
            }

            if (props.step === '.01') {
              const parts = value.split('.');
              if (parts.length > 1) {
                value = parts[0] + '.' + parts[1].slice(0, 2); // Restrict to two decimal places
                input.value = value;
              }

              emit('update:modelValue', value);
            } else if (props.step === '1') {
              value = value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
              input.value = value;
              emit('update:modelValue', value);
            } else {
              emit('update:modelValue', value);
            }
          }
        "
      />
      <label v-show="props.suffix !== ''" class="ml-2">{{
        props.suffix
      }}</label>
    </div>
    <p v-if="props.error" class="mt-2 text-sm text-red-600 dark:text-red-500">
      <span class="font-medium">Oops!</span> {{ props.errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { format } from 'date-fns';
import { ref, nextTick, onMounted, onUpdated } from 'vue';

const input = ref();

const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  modelValue: {
    default: '',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  class: {
    default: '',
    type: String,
  },
  type: {
    default: 'text',
    type: String,
  },
  minDate: {
    default: undefined,
    type: Date,
  },
  maxDate: {
    default: undefined,
    type: Date,
  },
  minNum: {
    default: null,
    type: Number,
  },
  maxNum: {
    default: null,
    type: Number,
  },
  tabindex: {
    default: null,
    type: Number,
  },
  suffix: {
    default: '',
    type: String,
  },
  autofocus: {
    default: false,
    type: Boolean,
  },
  required: {
    default: false,
    type: Boolean,
  },
  wrapperClass: {
    default: '',
    type: String,
  },
  placeholder: {
    default: '',
    type: String,
  },
  isValid: {
    default: false,
    type: Boolean,
  },
  error: {
    default: false,
    type: Boolean,
  },
  errorMessage: {
    default: '',
    type: String,
  },
  step: {
    default: null,
    type: String,
  },
  pattern: {
    default: null,
    type: String,
  },
  id: {
    default: null,
    type: String,
  },
  blur: {
    default: null,
    type: Function,
  },
});

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      input.value && input.value.focus();
      if (props.modelValue.length) input.value && input.value.select();
    });
  }
});

onUpdated(() => {
  if (props.autofocus) {
    nextTick(() => {
      input.value && input.value.focus();
    });
  }
});

const id = props.id ? props.id : `base-input-${Date.now()}`;

function assignMinValue() {
  switch (props.type) {
    case 'float':
      return parseFloat(props.minNum);
    case 'number':
      return parseInt(props.minNum);
    case 'date':
      return props.minDate ? format(props.minDate, 'yyyy-MM-dd') : null;
    default:
      return null;
  }
}

function assignMaxValue() {
  switch (props.type) {
    case 'float':
      return parseFloat(props.minNum);
    case 'number':
      return parseInt(props.maxNum);
    case 'date':
      return props.maxDate ? format(props.maxDate, 'yyyy-MM-dd') : null;
    default:
      return null;
  }
}

const emit = defineEmits(['update:modelValue']);
</script>
<style scoped>
.no-default-ring:focus {
  border: 1px #265170 solid;
}
</style>
