<template>
  <form>
    <div
      v-show="!renderComponent"
      class="flex items-center justify-center w-full h-full mx-auto"
    >
      <LoadingSpinner />
    </div>

    <div
      v-show="renderComponent"
      class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50"
    >
      <div class="px-4 py-2 bg-white rounded-t-lg0">
        <textarea
          id="activity_comment"
          v-model="message"
          rows="4"
          class="w-full px-3 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y min-h-[2.5rem]"
          placeholder="Write a note..."
          required
        ></textarea>
      </div>
      <div class="flex items-center justify-between px-3 py-2 border-t">
        <span>
          <!-- Action  -->
          <DropDownFlowBite
            :options="actionOptions"
            :parent-selection="actionSelected"
            unique-id-string="action"
            text-key="text"
            button-text="Action"
            @update-selection="
              selection => {
                actionSelected = selection;
              }
            "
          />
          <!-- Reason -->
          <DropDownFlowBite
            v-if="actionSelected?.id === 2"
            :options="wipActions"
            :show-search="true"
            :parent-selection="reasonSelected"
            unique-id-string="reason"
            text-key="name"
            button-text="Reason"
            @update-selection="
              selection => {
                reasonSelected = selection;
              }
            "
          />
          <DropDownFlowBite
            v-if="actionSelected?.id === 3"
            :options="completionActions"
            :show-search="true"
            :parent-selection="reasonSelected"
            unique-id-string="reason"
            text-key="name"
            button-text="Reason"
            @update-selection="
              selection => {
                reasonSelected = selection;
              }
            "
          />
        </span>
        <!-- Character Count, and Submit Button -->
        <div class="flex items-center space-x-1 sm:ps-2">
          <span
            class="text-sm text-gray-500"
            :class="{ 'text-red-500': characterCount > 1000 }"
            >Characters: {{ characterCount }}/1000</span
          >
          <button
            v-if="isValidToSubmit && !isSubmitting"
            type="button"
            class="text-white bg-primary-300 hover:bg-primary focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            @click="submitComment"
          >
            Submit
          </button>
          <button
            v-else
            type="button"
            class="text-white bg-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            disabled
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import DropDownFlowBite from '../../components/DropDownFlowBite.vue';
import { initFlowbite } from 'flowbite';
import { useUsersStore } from '@/stores/useUsers';
import { ActivityDropdown } from '@/utils/constants';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

/** User Selection and Notes */
const message = ref('');
const actionSelected = ref(null); // complete, add to wip, etc
const reasonSelected = ref(null); // Appeal Sent, Training needed, etc

/** Dropdown options */
const actionOptions = ref([]);
const completionActions = ref([]);
const wipActions = ref([]);

const workItemsStore = useWorkItemsStore();
const userStore = useUsersStore();

const isSubmitting = ref(false);
const renderComponent = ref(false);

const characterCount = computed(() => {
  return message.value.length;
});

/** Determine if the submit button should be disabled/active */
const isValidToSubmit = computed(() => {
  if (!actionSelected.value) {
    return false;
  }
  let completeOrWip = [2, 3].includes(actionSelected.value.id);
  let completeOrNote = [3, 4].includes(actionSelected.value.id);
  let noReasonSelected = !reasonSelected.value;

  if (message.value.length > 1000) {
    return false;
  }

  if (completeOrWip && noReasonSelected) {
    return false;
  }

  if (completeOrNote && message.value.trim().length === 0) {
    return false;
  }
  return true;
});

onMounted(async () => {
  initFlowbite();
  await workItemsStore.fetchActivitiesLookup(2);
  wipActions.value = workItemsStore.activityLookup;
  await workItemsStore.fetchActivitiesLookup(3);
  completionActions.value = workItemsStore.activityLookup;
  decideAvailableActionOptions();
  attemptToRestoreFromStorage();
  renderComponent.value = true;
});

/**
 * Edge case for when user navigates from one workItem -> another workItem
 */
watch(
  () => workItemsStore.workItem,
  /* eslint-disable no-unused-vars */
  newItem => {
    renderComponent.value = false;
    attemptToRestoreFromStorage();
    decideAvailableActionOptions();
    renderComponent.value = true;
  }
  /* eslint-enable no-unused-vars */
);

/**
 * responsible for maintaing parents activityDraft when new activity_type is selected
 */
watch(
  actionSelected,
  newVal => {
    if (newVal === null) return;
    emit('actionSelected', newVal);
    reasonSelected.value = null;
  },
  { flush: 'sync' }
);

/**
 * updates parents note every time new keystroke recorded in textarea
 */
watch(message, newVal => {
  emit('updateNote', newVal);
});

/** Chose reason for submitting (activity_subtype_id) */
watch(reasonSelected, item => {
  if (item === null) {
    return;
  }
  emit('reasonSelected', item);
});

/** Ensures valid, then uses workItem functions to submit activity, then resets state */
const submitComment = async () => {
  if (!isValidToSubmit.value) {
    return;
  }
  isSubmitting.value = true;
  if (actionSelected.value.id === 2) {
    await props.addToWip();
  }
  if (actionSelected.value.id === 3) {
    await props.markComplete();
  }
  if (actionSelected.value.id === 4) {
    await props.addNote();
  }
  resetState();
};

/** Determines what buttons are available in dropdown 1 */
const decideAvailableActionOptions = () => {
  actionOptions.value = ActivityDropdown;

  let currentUserIsAssigned =
    userStore.activeUser.auxo_user_id === workItemsStore.workItem.assignee_id;
  let inWIP = workItemsStore.getWorkItemValue('workflow_status') === 'WIP';
  let isComplete =
    workItemsStore.getWorkItemValue('workflow_status') === 'Complete';
  let isInactive =
    workItemsStore.getWorkItemValue('workflow_status') === 'Inactive';

  // remove complete and wip for inactive state
  if (isInactive) {
    actionOptions.value = [{ id: 4, text: 'Add Note', activity_type: 'Note' }];
    return;
  }
  // if in wip, completed, or unassigned user -> remove WIP option
  if (inWIP || isComplete || !currentUserIsAssigned) {
    actionOptions.value = actionOptions.value.filter(action => action.id !== 2);
    return;
  }
};

/**
 * Tries to repopulate the workItem message, action, & reason if their is existing
 * data in sessionStorage managed by parent (workItem.vue)
 */
const attemptToRestoreFromStorage = () => {
  let actionId = props.activityDraft.auxo_activity_type_id;
  let reasonId = props.activityDraft.auxo_activity_subtype_id;
  let isCompleteAction = actionId === 3;
  let isWipAction = actionId === 2;

  // edge case where user navigates by search from one work item to another
  // currentActivityDraft gets reset if there is no existing draft on new work item
  // we need to clear our state here so we don't show previous work items stuff since we didn't remount
  if (!actionId && !reasonId && props.activityDraft.note === '') {
    actionSelected.value = null;
    reasonSelected.value = null;
    message.value = '';
  }
  // user had set activity type
  if (actionId) {
    actionSelected.value = ActivityDropdown.find(
      element => element.id === actionId
    );
  }
  // user selected complete and a reason
  if (isCompleteAction && reasonId) {
    reasonSelected.value = completionActions.value.find(
      element => element.activity_subtype_id === reasonId
    );
  }
  // user selected wip and a reason
  if (isWipAction && reasonId) {
    reasonSelected.value = wipActions.value.find(
      element => element.activity_subtype_id === reasonId
    );
  }
  // repopulate message if exists
  if (props.activityDraft.note !== '') {
    message.value = props.activityDraft.note;
  }
};

const resetState = () => {
  isSubmitting.value = false;
  message.value = '';
  reasonSelected.value = null;
  actionSelected.value = null;
  emit('removeActivityDraft');
};

const props = defineProps({
  addToWip: {
    type: Function,
    default: () => 0,
  },
  addNote: {
    type: Function,
    default: () => 0,
  },
  markComplete: {
    type: Function,
    default: () => 0,
  },
  activityDraft: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits([
  'updateNote',
  'reasonSelected',
  'actionSelected',
  'removeActivityDraft',
]);
</script>
