import { defineStore } from 'pinia';
import { getAuthenticatedHeaders } from '@/utils/auth';
import { apiPost } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useChatbotStore = defineStore('chatbot', {
  state: () => ({
    notFound: false,
    responseLoading: false,
    sessionID: undefined,
  }),
  getters: {
    //no getters yet
  },
  actions: {
    async generateAndFormatResponse(prompt, props, user, messageList) {
      const headers = await getAuthenticatedHeaders();

      const payload = {
        message: prompt,
        phase: props.rspGroup.phase,
        status: props.rspGroup.status,
        reason: props.rspGroup.reason,
        userID: user.auxo_user_id,
        sessionID: this.sessionID,
        rsn: props.rspGroup.rsn,
        existingConversation: messageList,
      };

      const response = await apiPost(getPath('chat'), headers, payload);

      this.sessionID = response.data.sessionID;
      const output = response.data?.response_text ?? null;

      const presignedURL = response.data.presignedURL;

      const body = output;
      const linkText = '';
      const imageUrl = '';

      const text = {
        body,
        imageUrl,
        linkText,
        linkUrl: presignedURL,
        sessionID: this.sessionID,
      };

      return text;
    },
  },
});
