<template>
  <ModalComponent
    :modal-active="data.showSearchModal"
    :custom-style="
      data.showExpandedSearch
        ? {
            display: 'block',
            minWidth: '80%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
          }
        : { minWidth: '600px' }
    "
    :close="() => search_store.close()"
  >
    <div class="search-bar m-4">
      <div class="text-4xl my-3 text-primary">Search</div>
      <div
        class="flex"
        :class="{
          'flex-col': !data.showExpandedSearch,
          'flex-row items-center': data.showExpandedSearch,
        }"
      >
        <BaseInput
          v-model="data.input"
          placeholder="Search"
          autofocus
          class="text-primary"
          wrapper-class="w-full my-2"
          @keyup="() => searchDebounce()"
        />
        <div v-if="!data.showExpandedSearch" class="flex justify-end mt-5">
          <OutlinedButton
            class="w-20 mr-[1em] !text-base"
            @click="() => search_store.close()"
          >
            Cancel
          </OutlinedButton>
          <SolidButton
            type="submit"
            class="w-20 !text-base"
            @click="() => searchDebounce()"
          >
            Search
          </SolidButton>
        </div>
      </div>
      <div v-if="data.showExpandedSearch">
        <table class="drop-shadow-none">
          <thead>
            <tr>
              <td>
                <div
                  class="grid grid-flow-col auto-cols-fr text-gray-500 text-lg"
                >
                  <template v-for="(header, index) in HEADERS" :key="index">
                    <TableHeader
                      :id="header.key"
                      :title="header.value"
                      :show-sort="header.sort"
                      :width="header.width"
                      :type="header.type"
                      @sort="sortSearchResults"
                    />
                  </template>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div
                  v-show="search_store.isLoading"
                  class="flex items-center justify-center h-20"
                >
                  <LoadingSpinner />
                </div>
                <div
                  v-show="
                    !search_store.isLoading &&
                    search_store?.searchResults?.length
                  "
                  class="w-full text-left border-2"
                >
                  <template
                    v-for="(result, index) in search_store.searchResults"
                    :key="index"
                  >
                    <div
                      class="h-[50px] grid grid-flow-col auto-cols-fr text-sm text-primary py-2 cursor-pointer"
                      :class="index % 2 === 1 ? 'bg-gray-100' : 'bg-white'"
                      @click="redirect(result.revology_system_number)"
                    >
                      <div class="px-2 flex items-center">
                        {{ result.patient_name }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ result.account_number }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ result.medical_record_number }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ result.visit_number }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ result.claim_number }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ result.billing_type }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ formatDate(result.service_start_date) }}
                      </div>
                      <div class="px-2 flex items-center">
                        {{ formatDate(result.service_end_date) }}
                      </div>
                      <div class="px-2 relative">
                        <UserPicture
                          v-show="result?.auxo_user_id"
                          :user="users_store.getUserById(result?.auxo_user_id)"
                          :style="'bg-white'"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <VuePagination
        v-if="data.showExpandedSearch"
        local_store="search_pagination"
        css_classes="relative h-[70px] bg-white pt-[2em]"
        :records="search_store.searchRecords"
        type="Search Results"
        :limit="Number(search_store.searchLimit)"
        :page="search_store.searchPage"
        :options="[5, 10]"
        @paginate="paginate"
        @updateLimit="updateLimit"
      />
    </div>
  </ModalComponent>
  <div
    @click="
      () => {
        search_store.clear();
        search_store.setShowSearchModal(true);
      }
    "
  >
    <img src="@/assets/MagnifyGlass.svg" class="h-10 cursor-pointer p-1 mr-2" />
  </div>
</template>

<script setup>
import { onMounted, onUpdated, reactive, watch } from 'vue';
import router from '@/router';
import ModalComponent from './ModalComponent.vue';
import { useDebounceFn } from '@vueuse/core';
import { useSearchStore } from '@/stores/useSearch';
import { useUsersStore } from '@/stores/useUsers';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { formatDate } from '@/utils/helpers';
import BaseInput from './forms/BaseInput.vue';
import UserPicture from '@/components/UserPicture.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import VuePagination from '@/views/VuePagination.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';

const search_store = useSearchStore();
const users_store = useUsersStore();

const HEADERS = [
  { key: 'patient_name', value: 'Patient Name', sort: true },
  { key: 'account_number', value: 'Account', sort: true },
  { key: 'medical_record_number', value: 'Medical Record', sort: true },
  { key: 'visit_number', value: 'Visit', sort: true },
  { key: 'claim_number', value: 'Claim', sort: true },
  {
    key: 'billing_type',
    value: 'Billing Type',
    sort: true,
  },
  {
    key: 'service_start_date',
    value: 'Service Start Date',
    sort: true,
    type: 'date',
    format: 'Pp',
    truncateTimezone: false,
  },
  {
    key: 'service_end_date',
    value: 'Service End Date',
    sort: true,
    type: 'date',
    format: 'Pp',
    truncateTimezone: false,
  },
  { key: 'assigned_to', value: 'Assigned To', sort: false },
];

const data = reactive({
  input: search_store.input,
  showSearchModal: search_store.showSearchModal,
  showExpandedSearch: search_store.showExpandedSearch,
  currentlySearching: search_store.currentlySearching,
});

watch(data, newData => {
  const { input, showSearchModal, showExpandedSearch, currentlySearching } =
    newData;
  search_store.setInput(input);
  search_store.setIsSearching(currentlySearching);
  search_store.setShowSearchModal(showSearchModal);
  search_store.setShowExpandedSearch(showExpandedSearch);
});

onMounted(() => {
  data.input = search_store.input;
  data.showSearchModal = search_store.showSearchModal;
  data.showExpandedSearch = search_store.showExpandedSearch;
  data.currentlySearching = search_store.currentlySearching;
});

onUpdated(() => {
  data.input = search_store.input;
  data.showSearchModal = search_store.showSearchModal;
  data.showExpandedSearch = search_store.showExpandedSearch;
  data.currentlySearching = search_store.currentlySearching;
  props.showMetrics(false);
});

search_store.$subscribe(() => {
  data.input = search_store.input;
  data.showSearchModal = search_store.showSearchModal;
  data.showExpandedSearch = search_store.showExpandedSearch;
  data.currentlySearching = search_store.currentlySearching;
});

async function search() {
  search_store.setIsSearching(true);
  search_store.setShowExpandedSearch(true);
  const response = await search_store.queryBySearchTerm(data.input);
  search_store.setSearchResults(response);
  search_store.setIsSearching(false);
}

const searchDebounce = useDebounceFn(() => {
  if (!data.currentlySearching) {
    search();
  }
}, 1000);

function redirect(path) {
  router.push(`/lead/${path}`);
  search_store.setShowSearchModal(false);
  search_store.setJustSearched(true);
}

async function paginate(page) {
  await search_store.queryBySearchTerm(
    search_store.input,
    page,
    search_store.searchDirection,
    search_store.searchField
  );
}

async function updateLimit(limit) {
  search_store.searchLimit = parseInt(limit.value);
  await search_store.queryBySearchTerm(
    search_store.input,
    1,
    search_store.searchDirection,
    search_store.searchField
  );
}

async function sortSearchResults(direction, field) {
  search_store.searchDirection = direction;
  search_store.searchField = field;
  search_store.searchPage = 1;
  await search_store.queryBySearchTerm(search_store.input, 1, direction, field);
}

const props = defineProps({
  showMetrics: {
    default: () => {},
    type: Function,
  },
});
</script>

<style scoped></style>
