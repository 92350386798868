<template>
  <div class="w-20 h-full text-gray-100 bg-primary">
    <ul class="flex flex-col h-full bg-primary text-xs text-center ml-1">
      <template v-for="navItem in filteredPages" :key="navItem.name">
        <router-link v-slot="{ href, navigate }" :to="navItem.path">
          <div
            :class="
              activeTab == navItem.path ? activeStyle[0] : inactiveStyle[0]
            "
          >
            <li
              class="flex flex-col content-center items-center p-1 py-4 bg-primary text-gray-100 pr-1.5"
              :class="
                activeTab == navItem.path ? activeStyle[1] : inactiveStyle[1]
              "
            >
              <component :is="navItem.icon" class="w-7 h-7"></component>
              <a class="pt-1" :href="href" @click="navigate">{{
                navItem.sidebarName
              }}</a>
            </li>
          </div>
        </router-link>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { getFilteredPages } from '../router/index';

import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const route = useRoute();
const activeTab = ref(null);

const filteredPages = await getFilteredPages(true);

const setActiveTab = id => (activeTab.value = id);

watch(
  route,
  () => {
    const count = (route.path.match(/\//g) || []).length;
    if (count > 1) {
      setActiveTab(`/${route.path.split('/')[count - 1]}`);
    } else setActiveTab(route.path);
  },
  { deep: true, immediate: true }
);

const activeStyle = ['bg-primary', '!bg-[#f3f4f6] text-primary rounded-l'];
const inactiveStyle = ['bg-gray-100', '!text-white rounded-none'];
</script>
