<template>
  <div class="flex flex-col w-full text-xs">
    <div
      v-show="workItem_store.isLoading || users_store.usersLoading"
      class="flex bg-white h-36 p-2 justify-center items-center text-sm text-primary"
    >
      <LoadingSpinner />
    </div>
    <div
      v-show="
        workItem_store.workItemActivities.length === 0 &&
        !workItem_store.isLoading
      "
      class="flex bg-white h-36 p-2 justify-center items-center text-rev-base text-primary"
    >
      No activities found
    </div>
    <div
      v-for="(activity, i) in workItem_store.getActivityStreamRecords()"
      v-show="!workItem_store.isLoading && !users_store.usersLoading"
      :key="i"
    >
      <div class="p-2" :class="getRowColor(i)">
        <div class="text-gray-500 text-rev-sm font-semibold">
          {{ formatDate(activity.mod_timest, 'Pp', false) }}
        </div>
        <template v-if="activity.activity_type_id !== 6">
          <div class="flex mt-1">
            <div class="text-primary text-rev-sm mr-2 font-semibold">
              {{ activity.activity_type }}
              <span v-show="activity.activity_subtype !== null" class="fixed"
                >:</span
              >
            </div>
            <div class="text-rev-sm font-semibold">
              {{ activity.activity_subtype }}
            </div>
          </div>
          <div class="my-1 text-rev-sm">{{ activity?.note }}</div>
          <div class="font-light text-gray-500 text-rev-sm">
            Created by:
            <span class="text-primary text-rev-sm font-semibold">{{
              users_store.getUserByUsername(activity.username)?.full_name
            }}</span>
          </div>
        </template>
        <template v-if="activity.activity_type_id == 6">
          <div class="flex mt-1">
            <div class="text-primary text-rev-sm mr-2">
              Claim Status Check
              <span v-show="activity.activity_subtype !== null" class="fixed"
                >:</span
              >
            </div>
          </div>
          <div v-if="safeParse(activity.note, 'claim_number')">
            <b>Claim Number:</b> {{ safeParse(activity.note, 'claim_number') }}
          </div>
          <div v-if="safeParse(activity.note, 'icn')">
            <b>ICN:</b> {{ safeParse(activity.note, 'icn') }}
          </div>
          <div v-if="safeParse(activity.note, 'status')">
            <b>Status:</b> {{ safeParse(activity.note, 'status') }}
          </div>
          <div v-if="safeParse(activity.note, 'received_date')">
            <b>Received Date:</b>
            {{ safeParse(activity.note, 'received_date') }}
          </div>
          <div v-if="safeParse(activity.note, 'processed_date')">
            <b>Processed Date:</b>
            {{ safeParse(activity.note, 'processed_date') }}
          </div>
          <div
            v-if="
              safeParse(activity.note, 'status_category_code') &&
              safeParse(activity.note, 'status_category_code_description')
            "
          >
            <b>Status Category:</b>
            {{ safeParse(activity.note, 'status_category_code') }} -
            {{ safeParse(activity.note, 'status_category_code_description') }}
          </div>
          <div
            v-if="
              safeParse(activity.note, 'status_code') &&
              safeParse(activity.note, 'status_code_description')
            "
          >
            <b>Status Code:</b>
            {{ safeParse(activity.note, 'status_code') }} -
            {{ safeParse(activity.note, 'status_code_description') }}
          </div>
          <div v-if="safeParse(activity.note, 'check_number')">
            <b>Check Number:</b> {{ safeParse(activity.note, 'check_number') }}
          </div>
          <div v-if="safeParse(activity.note, 'remittance_date')">
            <b>Remittance Date:</b>
            ${{ safeParse(activity.note, 'remittance_date') }}
          </div>
          <div v-if="safeParse(activity.note, 'check_date')">
            <b>Check Date:</b>
            {{ formatDate(safeParse(activity.note, 'check_date'), 'P', true) }}
          </div>
          <div v-if="safeParse(activity.note, 'payment_amount')">
            <b>Payment Amount:</b>
            ${{ safeParse(activity.note, 'payment_amount') }}
          </div>
          <div class="font-light text-gray-500">
            Created by:
            <span class="text-primary">{{
              users_store.getUserByUsername(activity.username)?.full_name
            }}</span>
          </div>
        </template>
      </div>
    </div>
    <VuePagination
      v-if="!workItem_store.isLoading && !users_store.usersLoading"
      scroll-target="action-drop-down"
      :page="workItem_store.activityPage"
      local_store="activity_pagination"
      type="Recent Activities"
      :records="workItem_store.activityRecords"
      :limit="Number(workItem_store.activityLimit)"
      @paginate="paginate"
      @updateLimit="updateLimit"
    />
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useUsersStore } from '@/stores/useUsers';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { formatDate } from '@/utils/helpers';
import VuePagination from '@/views/VuePagination.vue';

const workItem_store = useWorkItemsStore();
const users_store = useUsersStore();

const getRowColor = i => {
  return i % 2 === 0 ? 'bg-white' : 'bg-gray-100';
};

function safeParse(activity, key) {
  try {
    return JSON.parse(activity)[key];
  } catch {
    console.error(`Error in parsing claim status field ${key}`);
  }
}

async function paginate(page) {
  await workItem_store.fetchWorkItemActivities(
    props.workItemId,
    page,
    workItem_store.activityDirection,
    workItem_store.activityField
  );
}

async function updateLimit(limit) {
  workItem_store.activityLimit = limit.value;
  await workItem_store.fetchWorkItemActivities(
    props.workItemId,
    1,
    workItem_store.activityDirection,
    workItem_store.activityField
  );
}

const props = defineProps({
  workItemId: {
    type: String,
    default: '',
  },
});
</script>
