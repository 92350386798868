<template>
  <date-picker
    v-slot="{ placeholder, clear }"
    v-model="dateValue"
    as-single
    placeholder="mm/dd/yyyy"
    :formatter="formatter"
    :disabled="props.disabled"
    :disable-date="props.dDate"
    :class="{
      error: props.error,
    }"
  >
    <div class="flex flex-col w-full">
      <div class="flex">
        <div
          class="flex-1"
          :class="{
            'bg-gray-50': props.disabled,
          }"
        >
          <button
            type="button"
            class="border-2 w-full rounded py-1.5 focus:border-black-500 text-left"
            :class="{
              '!border-red-500 border-r-2!rounded': error,
              'rounded-r-none': error && dateValue[0],
              '!border-r-0 rounded-r-none': dateValue[0] && !disabled,
              '!cursor-default': disabled,
            }"
          >
            <span class="text-gray-900 pl-2">
              {{ dateValue[0] || placeholder }}
            </span>
          </button>
        </div>
        <div v-show="dateValue[0] && !disabled" class="flex-shrink-0">
          <button
            type="button"
            class="border-2 w-full rounded p-2 focus:border-black-500 border-l-0 rounded-l-none"
            :class="{
              'rounder-l-none': error && !dateValue[0],
              '!border-red-500': error && dateValue[0],
            }"
            @click="
              e => {
                e.stopPropagation();
                clear();
              }
            "
          >
            <img class="w-4 h-4" :src="CloseIconSVG" />
          </button>
        </div>
      </div>
    </div>
  </date-picker>
</template>

<script setup>
import { formatDate } from '@/utils/helpers';
import { onMounted, ref, watch } from 'vue';
import CloseIconSVG from '@/assets/close-icon.svg';

const formatter = ref({
  date: 'MM/DD/YYYY',
  month: 'MMM',
});

const dateValue = ref([]);
onMounted(() => {
  if (props.modelValue) {
    const split = props.modelValue.split('/');
    const formatted = `${split[2]}-${split[0]}-${split[1]}`;
    dateValue.value = [formatDate(formatted, 'MM/dd/yyyy')];
  }
});

watch(props, () => {
  if (props.modelValue) {
    const split = props.modelValue.split('/');
    const formatted = `${split[2]}-${split[0]}-${split[1]}`;
    dateValue.value = [formatDate(formatted, 'MM/dd/yyyy')];
  }
});

watch(dateValue, () => {
  if (dateValue.value.length) {
    emit('update', dateValue.value[0]);
  } else {
    emit('update', null);
  }
});

const emit = defineEmits(['update']);

const props = defineProps({
  modelValue: {
    default: '',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  error: {
    default: false,
    type: Boolean,
  },
  dDate: {
    default: null,
    type: Function,
  },
});
</script>
