/**
 * Returns the passed path formatted for API calls.
 * @param {*} path
 * @param {$} override
 * @returns
 */
function getDynamicPath(path, override) {
  if (override) return `${override}${path}`;

  if (process.env.NODE_ENV === 'development') {
    path = window.location.origin.concat(['/'], path);
  }
  return path;
}

export default getDynamicPath;
