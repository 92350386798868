<template>
  <div
    v-if="
      props.rule.operator === 'is_null' ||
      props.rule?.operator === 'is_not_null'
    "
    class="flex flex-row flex-1 items-center"
  >
    <BaseInput :wrapper-class="'!mt-0 mx-2 w-full'" :disabled="true" />
  </div>
  <template
    v-else-if="
      props.rule.operator === 'between' ||
      props.rule?.operator === 'not_between'
    "
  >
    <div
      v-if="props.rule.type === 'varchar' || props.rule.type === 'decimal'"
      class="flex flex-row flex-1 items-center"
    >
      <BaseInput
        v-model="query.between_left"
        :wrapper-class="'!mt-0 mx-2 w-full'"
        :type="getInputType(props.rule)"
        :error="
          queryBuilder_store.attempt && !removeWhiteSpace(query.between_left)
        "
        :disabled="!props.rule.operator || props.gamePlanView"
        :placeholder="props.placeholder"
        :min-num="getMinValue(props.rule)"
        :step="getStep(props.rule)"
        :pattern="getPattern(props.rule)"
      />
      and
      <BaseInput
        v-model="query.between_right"
        :wrapper-class="'!mt-0 mx-2 w-full'"
        :type="getInputType(props.rule)"
        :error="
          queryBuilder_store.attempt &&
          (!removeWhiteSpace(query.between_right) ||
            query.between_right - query.between_left <= 0)
        "
        :disabled="!props.rule.operator || props.gamePlanView"
        :placeholder="props.placeholder"
        :min-num="getMinValue(props.rule)"
        :step="getStep(props.rule)"
        :pattern="getPattern(props.rule)"
      />
    </div>
    <div v-else class="flex flex-row flex-1 items-center">
      <QueryBuilderDatePicker
        v-model="query.between_left"
        class="pl-1 mx-1 w-full"
        :disabled="props.gamePlanView"
        :error="
          queryBuilder_store.attempt &&
          (!query.between_left ||
            !isBefore(
              new Date(query.between_left),
              new Date(query.between_right)
            ))
        "
        @update="
          event => {
            query.between_left = event;
          }
        "
      />
      and
      <QueryBuilderDatePicker
        v-model="query.between_right"
        class="mx-2 w-full"
        :disabled="props.gamePlanView"
        :error="
          queryBuilder_store.attempt &&
          (!query.between_right ||
            !isBefore(
              new Date(query.between_left),
              new Date(query.between_right)
            ))
        "
        @update="
          event => {
            query.between_right = event;
          }
        "
      />
    </div>
  </template>
  <div
    v-else-if="
      props.rule.operator === 'in' || props.rule?.operator === 'not_in'
    "
    class="flex flex-row flex-1 items-center"
    :style="`z-index: ${
      props.parentRule
        ? props.parentRule.rules?.length -
          props.indexChain[props.level] +
          (focus ? 10 : 0) +
          (hover ? 10 : 0)
        : 10 + (focus ? 10 : 0) + (hover ? 10 : 0)
    }`"
  >
    <div
      ref="multiSelectInputs"
      class="mx-2 w-full"
      :class="{
        error: !query?.value?.length && queryBuilder_store.attempt,
        'h-[36px] z-10 relative': true,
      }"
      @mouseover="() => ((focus = true), (hover = true))"
      @mouseleave="() => ((focus = open), (hover = false))"
    >
      <PhaseStatusReasonInput
        v-if="rule.field == 'phase_status_reason'"
        :value="query.value"
        :set-value="value => props.change({ key: value })"
        :error="queryBuilder_store.attempt && !query?.value?.length"
        :disabled="props.gamePlanView"
        :focused="focus"
        :set-open="newOpen => (open = newOpen)"
      />
      <CurrentPayerPlanInput
        v-else-if="rule.field == 'current_payer_plan'"
        :value="query.value"
        :set-value="value => props.change({ key: value })"
        :error="queryBuilder_store.attempt && !query?.value?.length"
        :disabled="props.gamePlanView"
        :focused="focus"
        :set-open="newOpen => (open = newOpen)"
      />
      <MultiSelectPopulate
        v-else-if="rule.field == 'last_completion_activity_subtype_id'"
        :value="query.value"
        label="name"
        reduce-key="activity_subtype_id"
        :set-value="value => props.change({ key: value })"
        :error="queryBuilder_store.attempt && !query?.value?.length"
        :disabled="props.gamePlanView"
        :focused="focus"
        :set-open="newOpen => (open = newOpen)"
        :fetch-options="() => workItems_store.fetchActivitiesLookup(3)"
        :get-options="workItems_store.activityLookup"
        :rule="props.rule"
      />
      <MultiSelect
        v-else-if="!checkIsNested(rule)"
        :value="query.value"
        :set-value="value => props.change({ key: value })"
        :error="queryBuilder_store.attempt && !query?.value?.length"
        :disabled="props.gamePlanView"
        :focused="focus"
        :set-open="newOpen => (open = newOpen)"
        :rule="props.rule"
      />
      <BaseInput
        v-else
        v-model="query.value"
        :wrapper-class="'!mt-0 mx-2 w-full'"
        :type="getInputType(props.rule)"
        :error="queryBuilder_store.attempt && !removeWhiteSpace(query.value)"
        :disabled="!props.rule.operator || props.gamePlanView"
        :placeholder="props.placeholder"
        :min-num="getMinValue(props.rule)"
        :step="getStep(props.rule)"
        :pattern="getPattern(props.rule)"
      />
    </div>
  </div>
  <div
    v-else-if="
      props.rule.input === 'datepicker' && props.rule.operator !== 'date_range'
    "
    class="flex flex-row flex-1 items-center"
  >
    <QueryBuilderDatePicker
      v-model="query.value"
      :disabled="props.gamePlanView"
      :error="queryBuilder_store.attempt && !query.value"
      class="mx-2"
      @update="
        event => {
          query.value = event;
        }
      "
    />
  </div>
  <div v-else class="flex-1">
    <BaseInput
      v-model="query.value"
      :wrapper-class="'!mt-0 mx-2'"
      :type="getInputType(props.rule)"
      :error="queryBuilder_store.attempt && !removeWhiteSpace(query.value)"
      :disabled="!props.rule.operator || props.gamePlanView"
      :placeholder="props.placeholder"
      :min-num="getMinValue(props.rule)"
      :step="getStep(props.rule)"
      :pattern="getPattern(props.rule)"
    />
  </div>
</template>

<script setup>
import { reactive, watch, onUpdated, ref } from 'vue';
import BaseInput from '@/components/forms/BaseInput.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { WORKLIST_ATTRIBUTES } from './constants';
import PhaseStatusReasonInput from './tree-inputs/PhaseStatusReasonInput';
import CurrentPayerPlanInput from './tree-inputs/CurrentPayerPlanInput';
import { onClickOutside } from '@vueuse/core';
import MultiSelect from './MultiSelect.vue';
import MultiSelectPopulate from './MultiSelectPopulate.vue';
import QueryBuilderDatePicker from './tree-inputs/QueryBuilderDatePicker.vue';
import { isBefore } from 'date-fns';

const props = defineProps({
  change: {
    type: Function,
    default: () => 0,
  },
  rule: {
    type: Object,
    default: () => {},
  },
  level: {
    type: Number,
    default: 0,
  },
  keyChain: {
    type: Array,
    default: () => [],
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: 'Value',
  },
  gamePlanView: {
    type: Boolean,
    default: false,
  },
  parentRule: {
    type: Object,
    default: () => {},
  },
});

const focus = ref(false);
const hover = ref(false);
const open = ref(false);
const multiSelectInputs = ref(null);

onClickOutside(multiSelectInputs, () => {
  focus.value = false;
  open.value = false;
});

const queryBuilder_store = useQueryBuilderStore();
const workItems_store = useWorkItemsStore();

const checkIsNested = rule => {
  return WORKLIST_ATTRIBUTES[rule.field]?.nested || false;
};

const getInputType = rule => {
  if (rule.input === 'datepicker') {
    if (rule.operator === 'date_range') return 'number';
    return 'date';
  } else if (rule.input === 'number') {
    if (rule.dtype === 'decimal') return 'float';
    else return 'number';
  } else return 'text';
};

const getMinValue = rule => {
  if (rule.operator === 'date_range') return 1;
  return null;
};

const getStep = rule => {
  if (rule.type === 'decimal') return '.01';
  else if (rule.input === 'number' || rule.operator === 'date_range')
    return '1';
  return null;
};

const getPattern = rule => {
  // eslint-disable-next-line no-useless-escape
  if (rule.type === 'decimal') return '^\d+(\.\d{1,2})?$';
  return null;
};

const query = reactive({
  id: props.keyChain[props.level],
  value: props.rule.value,
  between_left: props.rule.value?.[0],
  between_right: props.rule.value?.[1],
});

const removeWhiteSpace = value => {
  if (typeof value != 'string') return value;
  return value.trim();
};

onUpdated(() => {
  query.id = props.keyChain[props.level];
  if (
    props.rule.operator === 'between' ||
    props.rule.operator === 'not_between'
  ) {
    query.between_left = props.rule.value?.[0];
    query.between_right = props.rule.value?.[1];
  } else {
    query.value = props.rule.value;
  }
});

watch(query, () => {
  if (
    props.rule.operator === 'between' ||
    props.rule.operator === 'not_between'
  ) {
    props.change({ key: [query.between_left, query.between_right] });
  } else {
    props.change({ key: query.value });
  }
});
</script>
