<template>
  <div role="status" class="max-w-sm animate-pulse">
    <div class="mb-4">Thinking...</div>
    <div class="h-2 bg-gray-300 rounded-full max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-300 rounded-full mb-2.5"></div>
    <div class="h-2 bg-gray-300 rounded-full max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-300 rounded-full max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-300 rounded-full max-w-[360px]"></div>
  </div>
</template>
