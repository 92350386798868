<template>
  <section class="flex items-center bg-gray-5">
    <div class="w-full mx-auto">
      <div class="relative px-4 bg-white rounded-t-lg">
        <div class="flex items-center justify-between pt-3 md:pb-4"></div>
        <div
          class="flex flex-col-reverse items-stretch justify-between space-y-3 md:flex-row md:items-center md:space-y-0"
        >
          <div
            class="flex flex-col w-full space-y-3 lg:w-2/3 md:space-y-0 md:flex-row md:items-center"
          >
            <form class="flex-1 w-full md:max-w-sm md:mr-4">
              <div class="relative">
                <BaseInput
                  id="admin-search"
                  v-model="search_criteria"
                  type="search"
                  placeholder="Search..."
                  class="block w-full p-2 pl-10 text-sm text-gray-900 focus:!border-gray-300 border border-gray-300 rounded-lg bg-gray-50"
                ></BaseInput>
                <img
                  :src="MagnifyGlassDarkIcon"
                  class="absolute top-3 left-3.5"
                />
              </div>
            </form>
          </div>
          <div
            class="flex flex-col items-stretch justify-end flex-shrink-0 w-full pb-4 md:pb-0 md:w-auto md:flex-row md:items-center md:space-x-3"
          >
            <SolidButton
              type="button"
              class="flex items-center justify-center px-4 py-2 !text-sm font-medium text-white rounded-lg bg-primary hover:bg-primary-hover focus:ring-2 focus:ring-primary-300 focus:outline-none"
              @click="openCreateModal"
            >
              <img class="h-5 w-5 mr-1.5 -ml-1" :src="PlusIcon" />
              Add new user
            </SolidButton>
          </div>
        </div>
        <div class="flex flex-wrap pb-4">
          <div
            class="hidden mt-3 mr-4 text-sm font-medium text-gray-900 md:flex items-center"
          >
            Show:
          </div>
          <div class="flex flex-wrap">
            <div
              v-for="option in ['all', 'active', 'inactive']"
              :key="option"
              class="flex items-center mt-3 mr-4"
            >
              <input
                :id="option + '-users'"
                v-model="show_only"
                type="radio"
                :value="option"
                name="show-only"
                class="w-4 h-4 bg-gray-100 border-gray-300 text-primary"
              />
              <label
                :for="option + '-users'"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                {{ option.charAt(0).toUpperCase() + option.slice(1) }} Users
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch, onMounted, inject } from 'vue';
import { useAdminStore } from '@/stores/useAdmin';
import MagnifyGlassDarkIcon from '@/assets/Magnifying_Glass_Dark.svg';
import SolidButton from '@/components/buttons/SolidButton.vue';
import PlusIcon from '@/assets/plus-icon.svg';
import BaseInput from '../../components/forms/BaseInput.vue';

const admin_store = useAdminStore();

const show_only = ref('active');
const search_criteria = ref('');

const userModalControls = inject('userModalControls');

onMounted(async () => {
  if (admin_store.usersLoading == false) {
    await admin_store.getUsers(true);
    await admin_store.fetchExistingEmails();
  }
});

watch(show_only, newValue => {
  if (newValue == 'active') {
    admin_store.tableViewFilter = true;
  } else if (newValue == 'inactive') {
    admin_store.tableViewFilter = false;
  } else {
    admin_store.tableViewFilter = null;
  }
  admin_store.getUsers(admin_store.tableViewFilter);
});

watch(search_criteria, newValue => {
  admin_store.searchCriteria = newValue;
  admin_store.getFilteredUsers(newValue);
});

function openCreateModal() {
  admin_store.modalAction = 'create';
  admin_store.selectedUser = {};
  userModalControls.value.show();
}
</script>
