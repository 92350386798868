<template>
  <ModalComponent
    :modal-active="!!Object.keys(gameplan_store.deleteModal || {}).length"
    title=""
    :close="() => (gameplan_store.deleteModal = {})"
  >
    <div class="flex flex-col justify-center items-center">
      <h1 class="text-2xl text-center mb-3">
        Delete
        {{
          gameplan_store.deleteModal.is_priority === true
            ? 'Priority Project'
            : 'Worklist'
        }}
      </h1>

      <div class="text-center flex flex-col text-sm">
        <span
          >Are you sure you want to delete
          <span class="whitespace-normal"
            >"{{ gameplan_store.deleteModal?.work_list_name }}"?</span
          >
        </span>
      </div>
      <div class="mt-5 flex justify-evenly">
        <OutlinedButton
          class="light w-36 mr-2"
          @click="() => (gameplan_store.deleteModal = {})"
        >
          Cancel
        </OutlinedButton>
        <SolidDeleteButton
          class="delete w-36"
          @click="
            () => gameplan_store.deleteWorklist(gameplan_store.deleteModal)
          "
        >
          Delete
        </SolidDeleteButton>
      </div>
    </div>
  </ModalComponent>
  <table
    id="game_plan_table"
    class="text-sm cursor-default table-fixed relative z-10"
  >
    <thead class="sticky bg-white">
      <tr>
        <template v-for="header in GAME_PLAN_TABLE_HEADERS" :key="header.key">
          <TableHeader
            :id="header.key"
            :title="header.value"
            :type="header.type"
            :show-sort="header.sort"
            :width="header.width"
            :centered="header.centered"
            :edit="header.edit"
            :edit-mode="gameplan_store.editMode"
          />
        </template>
      </tr>
    </thead>
    <tbody
      @dragstart="handleDragStart"
      @dragover="handleDragOver"
      @drop="handleDragDrop"
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
    >
      <template
        v-for="(worklist, rowIndex) in gameplan_store.worklists.map(
          worklist => {
            const { bold_end, bold_start } = calculateRowStyle(worklist) || {};
            return {
              ...worklist,
              bold_end,
              bold_start,
            };
          }
        )"
        :key="worklist.work_list_id"
      >
        <tr
          :data-index="rowIndex"
          class="draggable"
          :draggable="gameplan_store.editMode === true"
          :class="{
            'bg-gray-100': rowIndex % 2 === 1,
            'hover:bg-secondary-hover cursor-pointer': true,
            'worklist-starting-soon bold-start-timest': worklist.bold_start,
            'worklist-ending-soon bold-end-timest': worklist.bold_end,
          }"
          @click="selectRow(worklist)"
        >
          <td
            v-for="header in GAME_PLAN_TABLE_HEADERS.filter(header => {
              return (
                header.edit == undefined ||
                (header.edit === true && gameplan_store.editMode === true)
              );
            })"
            :key="header.key"
          >
            <TableCellView
              :item="worklist"
              :header="header"
              :selected="selectedRow == worklist.work_list_id"
              :edit="header.edit"
              :edit-mode="gameplan_store.editMode"
              :index="rowIndex"
            />
          </td>
        </tr>
        <tr v-if="selectedRow == worklist.work_list_id">
          <td :colspan="getColLength()">
            <div class="tabs-container pl-[7em]">
              <tabs
                ref="worklistTabs"
                :options="{
                  useUrlFragment: false,
                  defaultTabHash: 'worklist_details',
                }"
                @changed="
                  e => {
                    setCurrentTab(e.tab.name);
                  }
                "
              >
                <tab id="worklist_details" name="Worklist Details">
                  <QueryBuilder :game-plan-view="true" />
                </tab>
                <tab
                  v-if="
                    !gameplan_store.editMode &&
                    gameplan_store.selectedStrategyExecution.is_current
                  "
                  id="preview"
                  name="Preview"
                >
                  <div
                    class="border-primary-600 rounded-md rounded-tl-none shadow-md overflow-hidden"
                  >
                    <GamePlanPreviewTable
                      :work-list-id="worklist.work_list_id"
                      :current-tab="currentTab"
                    />
                  </div>
                </tab>
              </tabs>
            </div>
          </td>
        </tr>
      </template>
      <tr
        v-show="
          gameplan_store.worklistsLoading && !gameplan_store.worklists?.length
        "
      >
        <td :colspan="getColLength()">
          <div class="flex items-center justify-center">
            <spinner size="8" class="fill-primary" />
          </div>
        </td>
      </tr>
      <tr
        v-show="
          !gameplan_store.worklists?.length && !gameplan_store.worklistsLoading
        "
      >
        <td :colspan="getColLength()">
          <div class="flex items-center justify-center h-8"></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import 'vue3-toastify/dist/index.css';
import { nextTick, onUpdated, ref, watch } from 'vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import TableCellView from '@/views/common/TableCellView.vue';
import { Spinner } from 'flowbite-vue';
import QueryBuilder from '../query-builder/QueryBuilder.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import ModalComponent from '@/components/ModalComponent.vue';
import SolidDeleteButton from '@/components/buttons/SolidDeleteButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import GamePlanPreviewTable from './GamePlanPreviewTable.vue';
import cloneDeep from 'lodash/cloneDeep';
import { moveAndAdjustRank } from '../../utils/helpers';

const queryBuilder = useQueryBuilderStore();
const gameplan_store = useGamePlanStore();
const selectedRow = ref();
const worklistTabs = ref(null);
const currentTab = ref(null);

const setCurrentTab = tab => (currentTab.value = tab);

const GAME_PLAN_TABLE_HEADERS = [
  {
    key: 'move',
    value: '',
    width: '2.5em',
    type: 'hidden',
    sort: false,
    edit: true,
  },
  { key: 'dropdown', value: '', width: '3em', type: 'hidden', sort: false },
  { key: 'rank', value: 'Rank', width: '4em', type: 'rank', sort: false },
  { key: 'work_list_name', value: 'Worklist', sort: false },
  { key: 'owner', value: 'Owner', sort: false },
  { value: 'Balance', key: 'total_balance', type: 'currency', sort: false },
  { value: 'Work Items', key: 'total_work_items', type: 'count', sort: false },
  {
    value: 'Starts',
    key: 'start_timest',
    type: 'date',
    sort: false,
  },
  {
    value: 'Expires',
    key: 'end_timest',
    type: 'date',
    sort: false,
  },
  {
    key: 'work_list_assignee',
    value: 'Assignees',
    centered: true,
    sort: false,
  },
  { key: 'is_priority', value: '', width: '4em' },
  {
    key: 'copy',
    value: '',
    width: '3em',
    type: 'hidden',
    sort: false,
    edit: true,
  },
  {
    key: 'edit',
    value: '',
    width: '3em',
    type: 'hidden',
    sort: false,
    edit: true,
  },
  {
    key: 'trash',
    value: '',
    width: '3em',
    type: 'hidden',
    sort: false,
    edit: true,
  },
];

// Close selected row in edit mode
watch(
  () => gameplan_store.getEditMode,
  () => {
    selectedRow.value = null;
  }
);

function calculateRowStyle(worklist) {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const expirationDate = new Date(worklist.end_timest).setHours(0, 0, 0, 0);
  const startDate = new Date(worklist.start_timest).setHours(0, 0, 0, 0);

  const diffExpiration = expirationDate - currentDate;
  const diffStart = startDate - currentDate;

  const daysDiffExpiration = Math.floor(diffExpiration / (1000 * 60 * 60 * 24));
  const daysDiffStart = Math.floor(diffStart / (1000 * 60 * 60 * 24));

  if (daysDiffStart > 0 && gameplan_store.isCurrentGameplanSelected) {
    return {
      bold_start: true,
    };
  } else if (
    daysDiffExpiration >= -1 &&
    daysDiffExpiration < 3 &&
    gameplan_store.isCurrentGameplanSelected
  ) {
    return {
      bold_end: true,
    };
  } else {
    return null;
  }
}

/* Start Drag and Drop Logic */

function getColLength() {
  return GAME_PLAN_TABLE_HEADERS.filter(header => {
    return (
      header.edit == undefined ||
      (header.edit === true && gameplan_store.editMode === true)
    );
  }).length;
}

let dragStartIndex;

onUpdated(() => {
  addEventListeners();
});

function swapItems(fromIndex, toIndex) {
  if (fromIndex == toIndex) return;

  const fromItem = gameplan_store.worklists.find(
    (_, index) => index == fromIndex
  );
  const toItem = gameplan_store.worklists.find((_, index) => index == toIndex);

  if (fromItem.is_default || toItem.is_default) return;

  if (
    (toItem.is_priority && !fromItem.is_priority) ||
    (!toItem.is_priority && fromItem.is_priority) ||
    toIndex === -1
  ) {
    return;
  }

  gameplan_store.worklists = moveAndAdjustRank(
    gameplan_store.worklists,
    fromItem,
    toIndex
  );

  gameplan_store.previous_worklists = cloneDeep(gameplan_store.worklists);
}

function getDraggableTarget(event) {
  if (event.target.classList.contains('draggable')) {
    return event.target;
  }
  return event.target.closest('.draggable');
}

function handleDragStart(e) {
  const target = getDraggableTarget(e);
  if (target) dragStart.call(target, e);
}

function handleDragOver(e) {
  const target = getDraggableTarget(e);
  e.preventDefault(); // needed for drop event
  if (target) dragOver.call(target, e);
}

function handleDragDrop(e) {
  const target = getDraggableTarget(e);
  e.stopPropagation();
  if (target) dragDrop.call(target, e);
}

function handleDragEnter(e) {
  const target = getDraggableTarget(e);
  if (target) dragEnter.call(target, e);
}

function handleDragLeave(e) {
  const target = getDraggableTarget(e);
  if (target) dragLeave.call(target, e);
}

function addEventListeners() {
  const tableContainer = document.getElementById('game_plan_table');

  tableContainer.addEventListener('dragstart', e => {
    const target = getDraggableTarget(e);
    if (target) dragStart.call(target, e);
  });

  tableContainer.addEventListener('dragover', handleDragOver);

  tableContainer.addEventListener('drop', handleDragDrop);

  tableContainer.addEventListener('dragenter', handleDragEnter);

  tableContainer.addEventListener('dragleave', handleDragLeave);
}

let intervalLoop;

function scrollTo(y) {
  const el = document.getElementById('game_plan_table');
  if (el) {
    clearInterval(intervalLoop);
    intervalLoop = setInterval(() => {
      el.scrollBy({
        top: y,
        left: 0,
      });
    }, 1);
  }
}

function dragStart() {
  dragStartIndex = +this.closest('tr').getAttribute('data-index');
}
function dragEnter() {
  this.classList.add('bg-primary-100');
}
function dragLeave() {
  this.classList.remove('bg-primary-100');
  clearInterval(intervalLoop);
  intervalLoop = null;
}

function dragOver(e) {
  e.preventDefault();

  const cursorPosY = e.pageY;
  const el = document.getElementById('game_plan_table');
  const rect = el.getBoundingClientRect();
  const SCROLL_ZONE_HEIGHT = 150;

  if (cursorPosY < rect.y + SCROLL_ZONE_HEIGHT) scrollTo(-1);
  if (cursorPosY > rect.y + rect.height - SCROLL_ZONE_HEIGHT) scrollTo(1);

  this.classList.add('bg-primary-100');
}

function dragDrop() {
  const dragEndIndex = +this.getAttribute('data-index');
  swapItems(dragStartIndex, dragEndIndex);
  this.classList.remove('bg-primary-100');
  clearInterval(intervalLoop);
  intervalLoop = null;
}

function selectRow(worklist) {
  if (worklist.work_list_id == selectedRow.value) selectedRow.value = null;
  else selectedRow.value = worklist.work_list_id;
  queryBuilder.worklist = worklist;
  queryBuilder.query = worklist.criteria_json;

  if (worklistTabs.value) {
    nextTick(() => {
      worklistTabs.value?.[0]?.selectTab('#worklist_details');
    });
  }
}

/* End Drag and Drop Logic */
</script>

<style scoped>
.bold-start-timest #start_timest-table-cell {
  font-weight: bold;
}
.bold-end-timest #end_timest-table-cell {
  font-weight: bold;
}

.worklist-starting-soon td div:not(#dropdown-table-cell) {
  @apply text-secondary;
}

.worklist-ending-soon td div:not(#dropdown-table-cell) {
  @apply text-red-one;
}
</style>
