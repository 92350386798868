export const CONDITIONALS = {
  AND: 'AND',
  OR: 'OR',
};

export const EMPTY_INPUT = {
  field: null,
  type: null,
  input: null,
  operator: null,
  value: null,
};

export const MAX_DEPTH = 3;

const MULTI_SELECT_OPERATORS_NO_NULL = [
  {
    key: 'in',
    label: 'Equal', // We label these Equal
  },
  {
    key: 'not_in',
    label: 'Not Equal', // And Not Equal intentionally
  },
];

const MULTI_SELECT_OPERATORS = [
  ...MULTI_SELECT_OPERATORS_NO_NULL,
  {
    key: 'is_null',
    label: 'Is Null',
  },
  {
    key: 'is_not_null',
    label: 'Is Not Null',
  },
];

const TEXT_OPERATORS = [
  {
    key: 'begins_with',
    label: 'Begins With',
  },
  {
    key: 'equal',
    label: 'Equal',
  },
  {
    key: 'not_equal',
    label: 'Not Equal',
  },
  {
    key: 'between',
    label: 'Between',
  },
  {
    key: 'not_between',
    label: 'Not Between',
  },
  {
    key: 'is_null',
    label: 'Is Null',
  },
  {
    key: 'is_not_null',
    label: 'Is Not Null',
  },
];

const NUMBER_OPERATORS = [
  {
    key: 'equal',
    label: 'Equal',
  },
  {
    key: 'not_equal',
    label: 'Not Equal',
  },
  {
    key: 'less_than',
    label: 'Less Than',
  },
  {
    key: 'less_than_or_equal',
    label: 'Less Than or Equal',
  },
  {
    key: 'greater_than',
    label: 'Greater Than',
  },
  {
    key: 'greater_than_or_equal',
    label: 'Greater Than or Equal',
  },
  {
    key: 'between',
    label: 'Between',
  },
  {
    key: 'not_between',
    label: 'Not Between',
  },
];

const DATE_OPERATORS = [
  {
    key: 'date_range',
    label: "x # of Days Prior to Today's Date",
  },
  ...NUMBER_OPERATORS,
  {
    key: 'is_null',
    label: 'Is Null',
  },
  {
    key: 'is_not_null',
    label: 'Is Not Null',
  },
];

export const WORKLIST_ATTRIBUTES = {
  service_end_date: {
    key: 'service_end_date',
    label: 'Service End Date',
    dtype: 'date',
    input: 'datepicker',
    operators: [...DATE_OPERATORS],
  },
  current_payer_plan: {
    // Special Case
    key: 'current_payer_plan',
    label: 'Current Payer/Plan',
    dtype: 'varchar',
    input: 'multi',
    nested: true,
    operators: MULTI_SELECT_OPERATORS_NO_NULL,
  },
  visit_type: {
    key: 'visit_type',
    label: 'Visit Type',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  billing_type: {
    key: 'billing_type',
    label: 'Billing Type',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  department: {
    key: 'department',
    label: 'Department',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  billing_provider: {
    key: 'billing_provider',
    label: 'Billing Provider',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  tax_id: {
    key: 'tax_id',
    label: 'Tax ID',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  location: {
    key: 'location',
    label: 'Location',
    dtype: 'varchar',
    input: 'multi',
    operators: [
      {
        key: 'begins_with',
        label: 'Begins With',
      },
      {
        key: 'contains',
        label: 'Contains',
      },
      ...MULTI_SELECT_OPERATORS,
    ],
  },
  phase_status_reason: {
    // Special Case
    key: 'phase_status_reason',
    label: 'Phase/Status/Reason',
    dtype: 'varchar',
    input: 'multi',
    nested: true,
    operators: MULTI_SELECT_OPERATORS_NO_NULL,
  },
  current_financial_class_name: {
    key: 'current_financial_class_name',
    label: 'Current Financial Class',
    dtype: 'varchar',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
  last_denial_date: {
    key: 'last_denial_date',
    label: 'Last Denial Date',
    dtype: 'date',
    input: 'datepicker',
    priorityOnly: true,
    operators: [...DATE_OPERATORS],
  },
  patient_name: {
    key: 'patient_name',
    label: 'Patient Name',
    dtype: 'varchar',
    input: 'text',
    operators: TEXT_OPERATORS,
  },
  current_balance: {
    key: 'current_balance',
    label: 'Current Balance',
    dtype: 'decimal',
    input: 'number',
    operators: NUMBER_OPERATORS,
  },
  last_claim_date: {
    key: 'last_claim_date',
    label: 'Last Claim Date',
    dtype: 'date',
    input: 'datepicker',
    operators: [...DATE_OPERATORS],
  },
  last_auxo_activity_dtm: {
    key: 'last_auxo_activity_dtm',
    label: 'Last User Activity Date',
    dtype: 'date',
    input: 'datepicker',
    operators: [...DATE_OPERATORS],
  },
  last_completion_activity_subtype_id: {
    key: 'last_completion_activity_subtype_id',
    label: 'Last Completion Action',
    dtype: 'integer',
    input: 'multi',
    operators: MULTI_SELECT_OPERATORS,
  },
};
