<template>
  <div
    :class="{
      error: props.error,
      'focused-input': props.focused,
      'unfocused-input': !props.focused,
      'payer-plan-input': true,
    }"
  >
    <v-select
      :model-value="payerPlans"
      :options="payerPlanOptions"
      multiple
      :filter="searchPayerPlans"
      :close-on-select="false"
      :deselect-from-dropdown="true"
      :clear-search-on-select="true"
      :disabled="props.disabled"
      :selectable="option => !isParentChecked(option)"
      :map-keydown="keydownHandlers"
      :dropdown-should-open="
        ({ noDrop, open, mutableLoading }) => {
          if (props.disabled) return false;
          if (open) setOpen(true);
          else setOpen(false);
          return noDrop ? false : (open && !mutableLoading) || focused;
        }
      "
      @search="
        (search, loading) => {
          const isSearching = search.length > 0;
          searching = isSearching;
          if (isSearching) {
            expanded = [...parentValues];
          } else {
            expanded = [];
          }
        }
      "
      @update:modelValue="payerPlans => updateVal(payerPlans)"
    >
      <!-- https://vue-select.org/api/slots.html#selected-option-container -->
      <template
        #selected-option-container="{
          option,
          multiple,
          disabled: isDisabled,
          deselect,
        }"
      >
        <div v-show="showSelectedOption(option)" class="vs__selected">
          {{
            option.level == 0
              ? option.label
              : formatChildLabel(option.child_label)
          }}
          <button
            v-if="multiple && !props.disabled"
            ref="deselectButtons"
            :disabled="isDisabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option.child_label || option.label}`"
            :aria-label="`Deselect ${option.child_label || option.label}`"
            @click="
              () => {
                deselect(option);
              }
            "
          >
            <img :src="CloseIcon2" />
          </button>
        </div>
      </template>

      <template #option="option">
        <div
          :style="{
            'margin-left': `${option.level * 2}em`,
            display: showOption(option) ? null : 'none',
            padding: '3px 20px',
          }"
        >
          <button
            v-if="option.level < 1"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();

                if (expanded.find(el => isEqual(el, option.value))) {
                  expanded = [];
                } else {
                  expanded = [option.value];
                }
              }
            "
          >
            <img
              :src="DownArrowSVG"
              class="h-3 mr-1 rotate-0"
              :class="{
                '!-rotate-90': !isExpanded(option),
              }"
            />
          </button>
          <input
            v-if="!option.isPageIndicator"
            type="checkbox"
            class="pointer-events-none"
            :checked="isChecked(option) || isParentChecked(option)"
            :disabled="isParentChecked(option)"
            :indeterminate="isIndeterminate(option) == true"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            "
          />
          {{ option.isPageIndicator ? pageNumber : option.label }}
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <button
          v-if="payerPlans?.length && !props.disabled"
          @click="
            e => {
              e.preventDefault();
              e.stopPropagation();
              reset();
              return false;
            }
          "
        >
          <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
        </button>

        <img
          :src="DownArrowSVG"
          v-bind="attributes"
          class="h-3 cursor-pointer ml-1"
        />
      </template>
      <template #list-footer>
        <li v-show="!searching" slot="list-footer" class="pagination flex mt-2">
          <button
            :style="{ display: hasPrevPage || hasNextPage ? null : 'none' }"
            :disabled="!hasPrevPage"
            class="disabled:opacity-75 disabled:pointer-events-none grow px-4 py-2 ml-2 mr-1 text-sm font-medium text-white bg-primary border border-primary rounded-md hover:bg-primary-hover focus:z-10"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                offset -= limit;
              }
            "
          >
            <font-awesome-icon :icon="faArrowLeft" />
            Previous
          </button>
          <button
            :style="{ display: hasNextPage || hasPrevPage ? null : 'none' }"
            :disabled="!hasNextPage"
            class="disabled:opacity-75 disabled:pointer-none disabled:pointer-events-none grow px-4 py-2 mr-2 ml-1 text-sm font-medium text-white bg-primary border border-primary rounded-md hover:bg-primary-hover focus:z-10"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                offset += limit;
              }
            "
          >
            Next
            <font-awesome-icon :icon="faArrowRight" />
          </button>
        </li>
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useLookupStore } from '@/stores/useLookups';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import CloseIcon2 from '@/assets/close-icon-2.svg';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const payerPlans = ref([]);
const expanded = ref([]);
const payerPlanOptions = ref([]);
const parentOptions = ref([]);
const parentValues = ref([]);
const childOptions = ref([]);
const offset = ref(0);
const limit = ref(10);
const previousChildArrayLength = ref(10);
const searching = ref(false);
const pageIndicator = {
  isPageIndicator: true,
  label: '...More',
  value: {},
  children: [],
  level: 1,
  parent: null,
};

const lookup_store = useLookupStore();

// Save the top level parent plans for list reset purposes
// Set options to only Parents
onMounted(() => {
  parentOptions.value = lookup_store.getPayerPlanOptions.filter(
    option => option.level == 0
  );
  payerPlanOptions.value = parentOptions.value;
  parentValues.value = parentOptions.value.map(o => o.value);
});

// Splice in children options when parent is expanded
watch(
  () => expanded.value,
  newValue => {
    offset.value = 0;
    const getPayerPlans = lookup_store.getPayerPlanOptions;
    const selectedPayerId = newValue[0]?.payer_code;

    const plans = getPayerPlans.filter(
      option =>
        option.level === 1 && option.parent.value.payer_code === selectedPayerId
    );

    plans.sort((a, b) => a.label.split('-')[0] - b.label.split('-')[0]);
    childOptions.value = plans;
    const filteredPlans = plans.slice(offset.value, limit.value + offset.value);
    const index = parentOptions.value.map(e => e.value).indexOf(newValue[0]);

    // Show ...More in list if there are more pages
    payerPlanOptions.value = [...parentOptions.value];
    if (hasNextPage.value) {
      payerPlanOptions.value.splice(
        index + 1,
        0,
        ...filteredPlans,
        pageIndicator
      );
    } else {
      payerPlanOptions.value.splice(index + 1, 0, ...filteredPlans);
    }
  }
);

// Splice in children options when Previous or Next button is clicked
watch(
  () => offset.value,
  newValue => {
    const filteredChildren = childOptions.value.slice(
      newValue,
      limit.value + offset.value
    );
    // If pagination is not needed then return
    if (childOptions.value.length < 10) return;

    const index = payerPlanOptions.value
      .map(e => e.value)
      .indexOf(expanded.value[0]);

    const current = [...payerPlanOptions.value];

    current.splice(
      index + 1,
      previousChildArrayLength.value,
      ...filteredChildren
    );
    payerPlanOptions.value = current;
    previousChildArrayLength.value = filteredChildren.length;
  }
);

// Watch parent query builder to update state
watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      const newMapping = uniq(
        props.value
          ?.map(el =>
            lookup_store.getPayerPlanOptions.find(option =>
              isEqual(option.value, el)
            )
          )
          .flatMap(option => {
            if (option.level == 0) return [option];
            return [option, ...option.children];
          })
          .filter(e => e),
        e => e.value
      );

      const oldMapping = payerPlans.value;

      if (isEqual(newMapping, oldMapping)) return;
      else payerPlans.value = newMapping;
    }
  }
);

// Check if button should be shown
const hasPrevPage = computed(() => {
  const prevOffset = offset.value - limit.value;
  return (
    childOptions.value.slice(prevOffset, limit.value + prevOffset).length > 0
  );
});

// Check if button should be shown
const hasNextPage = computed(() => {
  const prevOffset = offset.value + limit.value;
  return (
    childOptions.value.slice(prevOffset, limit.value + prevOffset).length > 0
  );
});

const pageNumber = computed(() => {
  const total = Math.ceil(childOptions.value.length / limit.value);
  const page = offset.value / 10 + 1;

  return `Page ${page} of ${total}`;
});

const formatChildLabel = label => {
  if (label.length <= 60) {
    return label;
  } else {
    return label.substring(0, 60) + '...';
  }
};

// Reset component state
const reset = () => {
  payerPlans.value = [];
  expanded.value = [];
  props.setValue([]);
};

// Is a checkbox checked
const isChecked = option => {
  return (
    payerPlans.value.findIndex(el => isEqual(el.value, option.value)) !== -1
  );
};

// Is a checkbox's parent checked
const isParentChecked = option => {
  if (option.isPageIndicator) return true;
  return (
    payerPlans.value.findIndex(el =>
      isEqual(el.value, option.parent?.value)
    ) !== -1
  );
};

// Is a checkbox indeterminate
const isIndeterminate = option => {
  return (
    option.children.some(
      child =>
        payerPlans.value.findIndex(el => isEqual(el.value, child.value)) !== -1
    ) && isChecked(option) !== true
  );
};

// Search all branches of the tree on a match, using parent_ids
function searchPayerPlans(options, search) {
  options = lookup_store.getPayerPlanOptions;
  const matchingPayerPlans = options
    .filter(el => {
      if (el.label.toLowerCase().trim().includes(search.toLowerCase().trim()))
        return true;
    })
    .flatMap(el => [el, ...el.children]);

  const newOptions = options.filter(
    el =>
      matchingPayerPlans.find(match => isEqual(match.value, el.value)) ||
      matchingPayerPlans.find(match => isEqual(match?.parent?.value, el?.value))
  );

  return newOptions;
}

// Is a chevron expanded
const isExpanded = option => {
  return expanded.value.find(el => isEqual(el, option.value));
};

// Returns true if an option is expanded
const showOption = option => {
  return (
    expanded.value.find(el => isEqual(el, option.parent?.value)) ||
    option.level == 0 ||
    option.isPageIndicator
  );
};

// Returns true if a selected should be shown
const showSelectedOption = option => {
  const parentIsChecked =
    payerPlans.value.findIndex(el =>
      isEqual(option.parent?.value, el.value)
    ) !== -1;

  return !parentIsChecked;
};

/**
 * updateVal is called on a change of the selected options, and dictate how payerPlans.value
 * will update.
 *
 * @param {*} options All selected options
 */
function updateVal(options) {
  const previousValues = payerPlans.value;
  const newValues = options;

  // Remove child values from deselected parent nodes
  function getFilteredOptions(options) {
    const optionsToRemove = previousValues
      .filter(
        previousValue =>
          !newValues
            .map(el => el.value)
            .find(el => isEqual(el, previousValue.value))
      )
      .flatMap(val => {
        return [val.parent, ...val.children].filter(e => e);
      });

    return options.filter(
      option => !optionsToRemove.find(el => isEqual(el.value, option.value))
    );
  }
  // Add parent vales from selected child nodes (when all are selected)
  function getParentParentSelections(options) {
    return lookup_store.getPayerPlanOptions.filter(parent => {
      // If all children exist in value return true
      return (
        parent.level < 1 &&
        parent.children.every(el =>
          getFilteredOptions(options).find(selection =>
            isEqual(el.value, selection.value)
          )
        )
      );
    });
  }
  const filteredOptions = getFilteredOptions(options);

  // Update payerPlans.value, check uniqueness
  payerPlans.value = uniqWith(
    [...filteredOptions, ...getParentParentSelections(filteredOptions)],
    (a, b) => isEqual(a, b)
  );

  props.setValue(
    payerPlans.value.filter(el => showSelectedOption(el)).map(el => el.value)
  );
}

function keydownHandlers(map) {
  return {
    ...map,
    //  delete, we prevent this.maybeDeleteValue()
    8: () => {},

    //  tab
    9: () => this.onTab(),

    //  enter
    13: e => {
      e.preventDefault();
      return this.typeAheadSelect();
    },

    //  esc
    27: () => this.onEscape(),

    //  up
    38: e => {
      e.preventDefault();
      return this.typeAheadUp();
    },

    //  down
    40: e => {
      e.preventDefault();
      return this.typeAheadDown();
    },
  };
}

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setValue: {
    type: Function,
    default: () => {},
  },
});
</script>

<style>
.vs__dropdown-option--disabled {
  background: white !important;
  color: black !important;
}

.vs__dropdown-option--highlight {
  background: #14b1e7 !important;
  color: black !important;
}

.vs__dropdown-option--deselect {
  background: #cecccc !important;
}

.focused-input .vs__selected-options,
.focused-input .vs__dropdown-toggle {
  height: inherit !important;
}

.unfocused-input .vs__selected-options,
.unfocused-input .vs__dropdown-toggle {
  height: 36px !important;
  overflow: hidden;
}

.payer-plan-input .vs__dropdown-option {
  padding: 0px;
}

.payer-plan-input .vs__selected {
  height: fit-content;
}
</style>
