/**
 * @param {string} endpoint URL to fetch
 * @param {Header} header Header Object
 * @param {URLSearchParams} queryStringParams Optional Params Object
 */

// GET request with optional query parameters
export function apiGet(endpoint, header, queryStringParams = null) {
  if (queryStringParams) {
    endpoint = `${endpoint}?${queryStringParams}`;
  }
  const response = request(endpoint, header, 'GET');
  return response;
}

// POST request
export function apiPost(endpoint, header, data, queryStringParams = null) {
  if (queryStringParams) {
    endpoint = `${endpoint}?${queryStringParams}`;
  }
  return request(endpoint, header, 'POST', data);
}

// PUT request
export function apiPut(endpoint, header, data) {
  return request(endpoint, header, 'PUT', data);
}

// DELETE request
export function apiDelete(endpoint) {
  return request(endpoint, 'DELETE');
}

async function request(endpoint, header, method, data = null) {
  const config = {
    method: method,
    ...header,
  };

  // Attach the body only for POST and PUT requests
  if (data && (method === 'POST' || method === 'PUT')) {
    config.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(endpoint, config);
    // Check if the response status is ok
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    // If there's no content (e.g. DELETE), return empty
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}
