<template>
  <div class="flex items-center text-center">
    <div
      v-if="props.userDetails"
      class="border-r pr-2 h-[80%] flex items-center text-primary text-md font-medium"
    >
      Stats
    </div>
    <div
      class="grid grid-cols-5"
      :class="{
        'font-normal text-primary w-[500px] whitespace-nowrap':
          props.userDetails,
      }"
    >
      <div style="grid-column: 1/2"></div>
      <div class="text-rev-base">Assigned</div>
      <div class="text-rev-base">Added to WIP</div>
      <div class="text-rev-base">Complete</div>
      <div class="text-rev-base">Time per work item</div>
      <div
        class="text-rev-base flex items-center justify-center"
        style="grid-column: 1/2"
      >
        Today
      </div>
      <!-- Todays Stats (assigned / wip / complete / time per work item)-->
      <div class="p-[3px]">
        {{ dailyAssigned() }}
      </div>
      <div class="p-[3px]">
        {{ dailyWip() }}
      </div>
      <div class="p-[3px]">
        {{ dailyComplete() }}
      </div>
      <div class="p-[3px]">
        {{ dailyTimeToComplete() }}
      </div>
      <div
        class="text-rev-base flex items-center justify-center"
        style="grid-column: 1/2"
      >
        Week
      </div>
      <!-- This Weeks Stats (assigned / wip / complete / time per work item)-->
      <div class="p-[3px]">
        {{ weeklyAssigned() }}
      </div>
      <div class="p-[3px]">
        {{ weeklyWip() }}
      </div>
      <div class="p-[3px]">
        {{ weeklyComplete() }}
      </div>
      <div class="p-[3px]">
        {{ weeklyTimeToComplete() }}
      </div>
      <div
        class="text-rev-base flex items-center justify-center"
        style="grid-column: 1/2"
      >
        Month
      </div>
      <!-- This Months Stats (assigned / wip / complete / time per work item)-->
      <div class="p-[3px]">
        {{ monthlyAssigned() }}
      </div>
      <div class="p-[3px]">
        {{ monthlyWip() }}
      </div>
      <div class="p-[3px]">
        {{ monthlyComlete() }}
      </div>
      <div class="p-[3px]">
        {{ monthlyTimeToComplete() }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMetrics } from '@/stores/useMetrics';
import { formatMilliseconds } from '../utils/helpers';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { formatNumber } from '../utils/helpers';

const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  userDetails: {
    type: Boolean,
    default: false,
  },
});

const dailyAssigned = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserDailyAssigned
      : metrics_store.activeUserDailyAssigned,
    0
  );

const dailyWip = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserDailyWip
      : metrics_store.activeUserDailyWip,
    0
  );

const dailyComplete = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserDailyComplete
      : metrics_store.activeUserDailyComplete,
    0
  );

const dailyTimeToComplete = () =>
  formatMilliseconds(
    props.userId
      ? metrics_store.selectedUserDailyTimeToComplete
      : metrics_store.activeUserDailyTimeToComplete
  );

const weeklyAssigned = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserWeeklyAssigned
      : metrics_store.activeUserWeeklyAssigned,
    0
  );
const weeklyWip = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserWeeklyWip
      : metrics_store.activeUserWeeklyWip,
    0
  );

const weeklyComplete = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserWeeklyComplete
      : metrics_store.activeUserWeeklyComplete,
    0
  );

const weeklyTimeToComplete = () =>
  formatMilliseconds(
    props.userId
      ? metrics_store.selectedUserWeeklyTimeToComplete
      : metrics_store.activeUserWeeklyTimeToComplete
  );

const monthlyAssigned = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserMonthlyAssigned
      : metrics_store.activeUserMonthlyAssigned,
    0
  );

const monthlyWip = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserMonthlyWip
      : metrics_store.activeUserMonthlyWip,
    0
  );

const monthlyComlete = () =>
  formatNumber(
    props.userId
      ? metrics_store.selectedUserMonthlyComplete
      : metrics_store.activeUserMonthlyComplete,
    0
  );

const monthlyTimeToComplete = () =>
  formatMilliseconds(
    props.userId
      ? metrics_store.selectedUserMonthlyTimeToComplete
      : metrics_store.activeUserMonthlyTimeToComplete
  );

const route = useRoute();
const metrics_store = useMetrics();

watch(
  route,
  () => {
    metrics_store.queryForUserMetrics(props?.userID);
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" scoped></style>
